import React from 'react';
import PropTypes from 'prop-types';

const MoveItems = (props) => {
  const handleDragStart = (data) => (evt) => {
    let fromItem = JSON.stringify({idx: data.idx});
    // console.log(`handleDragStart fromItem: ${fromItem}`);
    evt.dataTransfer.setData('dragContent', fromItem);
  };

  const handleDragOver = (evt) => {
    evt.preventDefault();
    return false;
  };

  const handleDrop = (data) => (evt) => {
    evt.preventDefault();
    let fromItem = JSON.parse(evt.dataTransfer.getData('dragContent'));
    let toItem = {idx: data.idx};
    // console.log(`handleDrop! fromItem: ${JSON.stringify(fromItem)}`);
    // console.log(`handleDrop! toItem: ${JSON.stringify(toItem)}`);
    props.moveItemsCallback(fromItem, toItem);
  };

  return (
    <div
      id={props.id}
      className=""
      draggable={props.draggable}
      onDragStart={handleDragStart({idx: props.item.idx})}
      onDragOver={handleDragOver}
      onDrop={handleDrop({idx: props.item.idx, id: props.id})}
    >
      {props.children}
    </div>
  );
};

MoveItems.propTypes = {
  id: PropTypes.number,
  children: PropTypes.object,
  moveItemsCallback: PropTypes.func,
  draggable: PropTypes.string,
  item: PropTypes.object,
};

export default MoveItems;
