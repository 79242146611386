import {utils, writeFile} from 'xlsx';

const exportDataCSV = (columns, data, fileName) => {
  const headers = columns.map((col) => col.Header);
  const rows = data.map((row) =>
    columns.map((col) =>
      row[col.accessor] !== undefined ? row[col.accessor] : ''
    )
  );

  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += headers.join(',') + '\n';
  csvContent += rows.map((e) => e.join(',')).join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

const exportDataXLSX = (columns, data, fileName) => {
  const headers = columns.map((col) => col.Header);
  const rows = data.map((row) =>
    columns.map((col) =>
      row[col.accessor] !== undefined ? row[col.accessor] : ''
    )
  );

  const worksheetData = [headers, ...rows];
  const worksheet = utils.aoa_to_sheet(worksheetData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  writeFile(workbook, fileName);
};

const exportMultipleTablesCSVSameTab = (formattedData, fileName) => {
  // Convert the data array to a CSV string
  const csvContent = formattedData.map((row) => row.join(',')).join('\n');

  // Create a blob from the CSV content
  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
  const link = document.createElement('a');

  // Create a link to download the CSV file
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const exportMultipleTablesCSVEachInSeparateTab = (workbook, fileName) => {
  // Export the workbook to an Excel file
  writeFile(workbook, fileName);
};

export {
  exportDataCSV,
  exportDataXLSX,
  exportMultipleTablesCSVSameTab,
  exportMultipleTablesCSVEachInSeparateTab,
};
